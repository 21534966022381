import React, { FC, useState, useEffect } from "react";

import { Button } from "../../../common/components/Button";

import styles from "./loginbox.module.scss";

import { Input } from "../../../common/components/Input";
import { motion } from "framer-motion";
import cn from "classnames";
import { useHistory } from "react-router-dom";
import { useUser } from "../../../contexts/UserProvider";
import { Cookie } from "../../../common/utils/cookie";

interface LoginboxProps {}

export const Loginbox: FC<LoginboxProps> = (props) => {
  let history = useHistory();
  const { user, login } = useUser();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.target.name === "email" && setEmail(e.target.value);
    e.target.name === "password" && setPassword(e.target.value);
  };

  const handlelogin = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    login(email, password).catch(() => setError(true));
  };

  useEffect(() => {
    if (user) {
      Cookie.setCookie("accessToken", user.accessToken);
      Cookie.setCookie("user", JSON.stringify(user.profile));
      history.push("./");
    }
  }, [history, user]);

  return (
    <motion.div animate={{ opacity: [0, 1], y: [10, 0] }}>
      <div className={styles.loginbox}>
        <h2>Herzlich willkommen</h2>
        <p>Bitte loggen Sie sich mit Ihren bekannten Zugangsdaten ein</p>
        {error && (
          <p className={styles.error}>Die Zugangsdaten sind nicht korrekt.</p>
        )}

        <form>
          <div className={styles.field} style={{ marginTop: 50 }}>
            <div>
              <label>VPF-Email</label>
            </div>
            <div style={{ marginTop: 10 }}>
              <Input
                name="email"
                type="text"
                placeholder="max.mustermann@vp-finance.de"
                className={cn(styles.formField)}
                required
                value={email}
                onChange={handleChange}
              />
            </div>

            <div style={{ marginTop: 30 }}>
              <label>Passwort</label>
            </div>
            <div style={{ marginTop: 10 }}>
              <Input
                name="password"
                type="password"
                placeholder="Passwort"
                className={styles.formField}
                required
                value={password}
                onChange={handleChange}
              />
            </div>

            <Button
              className={styles.loginButton}
              style={{ marginTop: 30, textAlign: "center" }}
              onClick={handlelogin}
              type="submit"
              fullwidth
            >
              Einloggen
            </Button>
          </div>
        </form>
      </div>
    </motion.div>
  );
};
