import React, { FC } from "react";

import styles from "./home.module.scss";

import { Container } from "../../common/components";
import { Newsbox } from "./Newsbox";
import { AppDashboard } from "./AppDashboard";
import { Grid, Row, Col } from "react-flexbox-grid";

interface HomeProps {}

export const Home: FC<HomeProps> = (props) => {
  return (
    <>
      <div className={styles.spacer}></div>
      <div
        className={styles.appWrapper}
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/background.jpg)`,
        }}
      >
        <Container>
          <Grid fluid>
            <Row className={styles.wrapper}>
              <Col md={12} lg={5} xl={4}>
                <Newsbox />
              </Col>
              <Col md={12} lg={7} xl={8}>
                <AppDashboard />
              </Col>
            </Row>
          </Grid>
        </Container>
      </div>
    </>
  );
};
