import React, { FC } from "react";
import styles from "./appbutton.module.scss";
import cn from "classnames";

import { AppButton as AppButtonProps } from "../../../../common/interfaces/AppButton";

export const AppButton: FC<AppButtonProps> = ({
  image,
  name,
  variant = "default",
  link,
}) => {
  return (
    <div className={styles.appButtonWrapper}>
      <a href={link} target="_blank" rel="noopener noreferrer">
        <div className={cn(styles.appButton, styles[variant])}>
          <img className={styles.appImage} src={image} alt={`App ${name}`} />
        </div>
        <div className={styles.appName}>{name}</div>
      </a>
    </div>
  );
};
