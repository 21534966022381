import React from "react";
import "./App.scss";
import { AppLayout } from "./layout";
import { routes } from "./routes";
import { withAuth } from "./common/hoc";

import { Switch, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <AppLayout>
        <Switch>
          {routes.map(({ path, Component, auth }) => (
            <Route path={path} key={path}>
              {auth ? withAuth(Component) : <Component />}
            </Route>
          ))}
        </Switch>
      </AppLayout>
    </div>
  );
}

export default App;
