import { AppButton } from "../interfaces/AppButton";

const PATH = `${process.env.PUBLIC_URL}/images/logos/`;

export const APPS: Array<AppButton> = [
  /* {
    name: "von Poll",
    image: `${PATH}/vp@2x.png`,
    variant: "internal",
    link: "https://www.vp-finance.de/w",
    group: "internal",
  }, */
  {
    name: "finCRM", // 1
    image: `${PATH}/fincrm.png`,
    variant: "default",
    link: "https://von-poll.fincrm.de",
    group: "popular",
  },
  {
    name: "Europace, BAUFINEX, FORUM", // 15
    image: `${PATH}/europace.png`,
    variant: "default",
    link: "https://www.europace2.de/partnermanagement/login.html?oeffne=%2Fuebersicht%2Findex.html",
    group: "general",
  },
  /* {
    name: "Forum Direkt", // 3.1
    image: `${PATH}/forum.png`,
    variant: "default",
    link: "https://mein.forum-direkt.de/login",
    group: "general",
  },
  {
    name: "Baufinex", // 3.2
    image: `${PATH}/baufinex.png`,
    variant: "default",
    link: "https://marktplatz.baufinex.de/login",
    group: "general",
  }, */
  {
    name: "eHyp", // 3
    image: `${PATH}/ehyp.png`,
    variant: "default",
    link: "https://www.ehyp.de",
    group: "general",
  },
  {
    name: "FinCompare", // 3
    image: `${PATH}/fincompare.png`,
    variant: "default",
    link: "https://app.fincompare.de/account/login",
    group: "general",
  },
  {
    name: "Ellwanger.Geiger", // 4
    image: `${PATH}/ellwangergeiger.png`,
    variant: "default",
    link: "https://www.alfcloud.de/calctoolellwangergeiger/login.aspx?ReturnUrl=%2fcalctoolellwangergeiger%2f",
    group: "general",
  },
  {
    name: "SDV", // 7
    image: `${PATH}/sdv.png`,
    variant: "default",
    link: " https://maklerextranet.sdv.ag/signin/von_poll",
    group: "popular",
  },
  {
    name: "Defino", // 9
    image: `${PATH}/generic01.png`,
    variant: "default",
    link: "https://defino.insinno.de/secure/login",
    group: "general",
  },
  {
    name: "Procheck24", // 10
    image: `${PATH}/procheck24.png`,
    variant: "default",
    link: "https://www.procheck24.de/auth/w/login",
    group: "general",
  },
  {
    name: "Solidvest", // 10
    image: `${PATH}/generic01.png`,
    variant: "default",
    link: "https://b2b.solidvest.de/login",
    group: "general",
  },
  {
    name: "Nextcloud", // 4
    image: `${PATH}/nextcloud.png`,
    variant: "default",
    link: "https://vp-finance-de.nextcloud.viakom.de",
    group: "general",
  },
  {
    name: "VP-Inside", // 5
    image: `${PATH}/inside.png`,
    variant: "default",
    link: "https://vpinside.von-poll.com/#{1}",
    group: "internal",
  },
  {
    name: "VPF Human Resources", // 13
    image: `${PATH}/recruiting.png`,
    variant: "default",
    link: "https://vpf-human-resources.de/",
    group: "internal",
  },
  {
    name: "Passwörter", // 6
    image: `${PATH}/password.png`,
    variant: "default",
    link: "https://account.vp-finance.de/#/",
    group: "internal",
  },
  {
    name: "Mail", // 6
    image: `${PATH}/mail.png`,
    variant: "default",
    link: " https://www.checkdomain.de/webmail",
    group: "internal",
  },
  {
    name: "Timebutler", // 14
    image: `${PATH}/timebuttler.png`,
    variant: "default",
    link: "https://timebutler.de/login/",
    group: "general",
  },
  {
    name: "WhoFinance",
    image: `${PATH}/whofinance.png`,
    variant: "default",
    link: "https://www.whofinance.de/fuer-berater/login/",
    group: "general",
  },
  {
    name: "Gut beraten", // 11
    image: `${PATH}/gutberaten.png`,
    variant: "default",
    link: "https://wbd.gutberaten.de/wbd-app/app/wicket/bookmarkable/de.gdv.wbd.app.login.WebAuthLoginPage",
    group: "popular",
  },
  {
    name: "Pricehubble", // 18
    image: `${PATH}/pricehubble.png`,
    variant: "default",
    link: "https://dash.pricehubble.com/login",
    group: "general",
  },
  {
    name: "Maklervertrag",
    image: `${PATH}/onlinemaklervertrag.png`,
    variant: "default",
    link: "https://www.onlinemaklervertrag.de/login",
    group: "general",
  },
  {
    name: "Maklerportal", // 18
    image: `${PATH}/maklerportal.png`,
    variant: "default",
    link: "https://app.maklerportal.flexperto.com/login/expert?redirect=%2Fdashboard%2Fhome",
    group: "general",
  },
  {
    name: "SDV-Bridge", // 7
    image: `${PATH}/sdv-bridge.png`,
    variant: "default",
    link: "https://sdv.br-idge.com/login-form.a",
    group: "popular",
  },
  /* {
    name: "Wüstenrot", // 18
    image: `${PATH}/wuestenrot.png`,
    variant: "default",
    link: "http://vertriebspartner.wuestenrot.de/",
    group: "general",
  },
  {
    name: "Deu. Vors. DB", // 12
    image: `${PATH}/vorsorge.png`,
    variant: "default",
    link: "https://www.deutschevorsorgedatenbank.de",
    group: "general",
  }, */
  
  
  /* {
    name: "Produktivo", // 16
    image: `${PATH}/produktivo.png`,
    variant: "default",
    link: "https://produktivo.com/login",
    group: "general",
  }, */
  /* {
    name: "STARPOOL", // 17
    image: `${PATH}/starpool.png`,
    variant: "default",
    link: "https://www.starpool.de",
    group: "popular",
  }, */
  
  
  
  /* {
    name: "Controlling",
    image: `${PATH}/vp@2x.png`,
    variant: "default",
    link: "",
    group: "internal",
  }, */
  /* {
    name: "Bottimo",
    image: `${PATH}/boot@2x.png`,
    variant: "default",
    link: "https://bottimmo.com/",
    group: "popular",
  }, */
  /* {
    name: "ImmoScout24",
    image: `${PATH}/immo.png`,
    variant: "default",
    link: "https://www.immobilienscout24.de/",
    group: "popular",
  }, */

  /* {
    name: "Abakus24",
    image: `${PATH}/abakus.png`,
    variant: "default",
    link: "https://www.abakus24.de/",
    group: "popular",
  }, */
];
