import React, { FC } from "react";
import { AppButton, AppButtonAnimation } from "./AppButton";
import styles from "./appdashboard.module.scss";

import { APPS } from "../../../common/constants/APPS";

/* const AppsByGroup = (group: AppButtonGroups) => (
  <div className={styles.apps}>
    {APPS.filter((e) => e.group === group).map(
      ({ name, image, variant, link, group }, i) => (
        <AppButtonAnimation i={i} name={name} key={name}>
          <AppButton
            name={name}
            image={image}
            variant={variant ? variant : "default"}
            link={link}
            key={name}
            group={group}
          />
        </AppButtonAnimation>
      )
    )}
  </div>
); */

const AllApps = () => (
  <div className={styles.apps}>
    {APPS.map(({ name, image, variant, link, group }, i) => (
      <AppButtonAnimation i={i} name={name} key={name}>
        <AppButton
          name={name}
          image={image}
          variant={variant ? variant : "default"}
          link={link}
          key={name}
          group={group}
        />
      </AppButtonAnimation>
    ))}
  </div>
);

export const AppDashboard: FC = () => {
  return <>{AllApps()}</>;
};
