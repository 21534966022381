import React, { FC, useState, useEffect } from "react";
import styles from "./iconbutton.module.scss";
import cn from "classnames";
import { motion, AnimatePresence } from "framer-motion";

interface IconButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {}

export const IconButton: FC<IconButtonProps> = (props) => {
  const { children, ...other } = props;
  const ANIMATION_DURATION = 500;

  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    setClicked(false);
    setTimeout(() => {
      setClicked(false);
    }, ANIMATION_DURATION);
  }, [clicked]);

  return (
    <button
      {...other}
      className={cn(styles.iconbutton, props.className)}
      onClick={(e: any) => {
        setClicked(true);
        if (props.onClick) {
          props.onClick(e);
        }
      }}
    >
      <AnimatePresence>
        {clicked && (
          <motion.span
            key="button"
            initial={{ scale: 0.5, opacity: 0 }}
            className={styles.animation}
            animate={{
              scale: 1,
              transition: { duration: ANIMATION_DURATION },
              opacity: [0, 0.1, 0],
            }}
            exit={{ scale: 1, opacity: [0, 0.1, 0] }}
          />
        )}
        <div className={styles.children}>{children}</div>
      </AnimatePresence>
    </button>
  );
};
