import React, {
  createContext,
  FC,
  useContext,
  useState,
  useEffect,
} from "react";

import { User } from "../common/interfaces/User";
import { login as postLogin } from "../api/auth/login";
import { Cookie } from "../common/utils/cookie";

interface LoginCredentials {
  profile: User;
  accessToken: string;
}

interface UserContextProps {
  user: LoginCredentials | undefined;
  login: (email: string, password: string) => Promise<any>;
  logout: () => Promise<any>;
}

const UserContext = createContext<UserContextProps>({
  user: undefined,
  login: () => new Promise(() => {}),
  logout: () => new Promise(() => {}),
});

const UserProvider: FC = ({ children }) => {
  const [user, setUser] = useState<LoginCredentials | undefined>();

  useEffect(() => {
    const accessToken = Cookie.getCookie(document.cookie, "accessToken");
    const user = Cookie.getCookie(document.cookie, "user");

    if (accessToken && user) {
      setUser({
        accessToken,
        profile: JSON.parse(user),
      });
    }
  }, []);

  const login = async (email: string, password: string): Promise<any> => {
    const user: LoginCredentials = await postLogin(email, password);
    if (user && user.accessToken) {
      setUser(user);
    }

    return user;
  };

  const logout = () =>
    new Promise((resolve, reject) => {
      Cookie.removeCookie("accessToken");
      Cookie.removeCookie("user");
      setUser(undefined);
      resolve();
    });

  const value = {
    user: user,
    login,
    logout,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => useContext(UserContext);

export default UserProvider;
