import React, { FC } from "react";
import { Headerbar } from "./components";

interface AppLayoutProps {}

export const AppLayout: FC<AppLayoutProps> = ({ children }) => {
  return (
    <>
      <Headerbar />
      {children}
    </>
  );
};
