type HttpMethod = "GET" | "POST" | "PUT" | "DELETE";
export interface FetchRestParams {
  [key: string]: any;
}

export const fetchRest = async (
  endpoint: string,
  method: HttpMethod = "GET",
  params: FetchRestParams | false = false,
  accessToken?: string
) => {
  const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3001";

  const QUERY_STRING: string =
    method === "GET" && params && Object.keys(params).length > 0
      ? `?${Object.keys(params)
          .map(
            (key) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
          )
          .join("&")}`
      : "";

  const headers = new Headers();
  headers.append("Accept", "application/json");

  headers.append("Authorization", `Bearer ${accessToken}`);

  if (method === "POST" || method === "PUT" || method === "DELETE") {
    headers.append("Content-Type", "application/json");
  }

  const FETCH_INIT: RequestInit = {
    method,
    headers,
  };

  if (
    (method === "POST" || method === "PUT" || method === "DELETE") &&
    params
  ) {
    FETCH_INIT.body = JSON.stringify(params);
  }

  return fetch(`${apiUrl}${endpoint}${QUERY_STRING}`, FETCH_INIT)
    .then((res) => res.json())
    .catch((e) => {
      throw e;
    });
};
