import React, { FC } from "react";

/* import { Button } from "../../../common/components/Button"; */

import styles from "./newsbox.module.scss";
import { motion } from "framer-motion";
import { useUser } from "../../../contexts/UserProvider";

interface NewsboxProps {}

export const Newsbox: FC<NewsboxProps> = (props) => {
  const { user } = useUser();
  return (
    <motion.div animate={{ opacity: [0, 1] }}>
      <div className={styles.newsbox}>
        <h1>
          Willkommen in der <br />
          <strong>VON POLL FINANCE SUITE</strong>,
          <br />
          {user?.profile.firstname}!
        </h1>
        <hr className={styles.divider} />
        <div className={styles.text}>
          Die Suite bietet Dir in Kombination mit dem Passwortmanager Bitwarden
          ein komfortables Nutzererlebnis. Sie geleitet Dich zu den gewünschten
          Websites und Bitwarden ermöglicht Dir einen schnellen und sicheren
          Login.
          <br />
          <br />
          Wir wünschen Dir viel Spaß mit der Suite!
          <br />
          <br />
          Dein VPF-Team
        </div>

        {/* <Button style={{ marginTop: 20 }}>Zum ganzen Artikel</Button> */}
      </div>
    </motion.div>
  );
};
