import React, { FC } from "react";
import cn from "classnames";

import styles from "./button.module.scss";
import { motion, useAnimation } from "framer-motion";

interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  fullwidth?: boolean;
}

export const Button: FC<ButtonProps> = (props) => {
  const { children, fullwidth = false, style, ...other } = props;
  const controls = useAnimation();

  const ANIMATION_DURATION = 0.3;

  const handleAnimation = () => {
    controls.start({
      scale: [0, 1, 1],
      opacity: [0.4, 0.4, 0],
      transition: { duration: ANIMATION_DURATION },
    });
  };

  return (
    <button
      {...other}
      className={cn(styles.button, `${props.className}`, {
        [`${styles.fullWidth}`]: fullwidth,
      })}
      style={style}
      onClick={(e: any) => {
        handleAnimation();
        if (props.onClick) {
          props.onClick(e);
        }
      }}
    >
      <motion.span
        key="button-1"
        style={{ scale: 0, opacity: 0 }}
        className={styles.animation}
        animate={controls}
      />
      <div className={styles.children}>{children}</div>
    </button>
  );
};
