import { FC } from "react";
import { Home } from "./pages/Home";
import { Login } from "./pages/Login";

interface Route {
  path: string;
  exact?: boolean;
  Component: FC;
  auth?: boolean;
}

export const routes: Array<Route> = [
  {
    path: "/login",
    Component: Login,
  },
  {
    path: "/",
    exact: true,
    Component: Home,
    auth: true,
  },
];
