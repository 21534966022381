import React, { FC } from "react";

import styles from "./input.module.scss";
import cn from "classnames";

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export const Input: FC<InputProps> = (props) => {
  return (
    <input {...props} className={cn(styles.inputComponent, props.className)} />
  );
};
