import React, { FC } from "react";
import { motion } from "framer-motion";

interface AppButtonAnimationProps {
  i: number;
  name: string;
}

const variants = {
  visible: (i: number) => ({
    opacity: 1,
    scale: 1,
    transition: {
      delay: i * 0.035,
    },
  }),
  hidden: { opacity: 0, scale: 0.1 },
};

export const AppButtonAnimation: FC<AppButtonAnimationProps> = ({
  children,
  i,
  name,
}) => {
  return (
    <motion.div
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      animate={"visible"}
      variants={variants}
      custom={i}
      initial={"hidden"}
      key={`motion-${name}`}
    >
      {children}
    </motion.div>
  );
};
