import React, { FC } from "react";

import { Loginbox } from "./components";

import styles from "./login.module.scss";

interface LoginProps {}

export const Login: FC<LoginProps> = (props) => {
  return (
    <div
      className={styles.loginWrapper}
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/background.jpg)`,
      }}
    >
      <div className={styles.loginBoxWrapper}>
        <Loginbox />
      </div>
    </div>
  );
};
