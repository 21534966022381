import React, { FC } from "react";
import styles from "./headerbar.module.scss";
import cn from "classnames";
import { FaUserCircle as IconUser } from "react-icons/fa";
import { FiLogOut as IconLogout } from "react-icons/fi";
import { IconButton } from "../../../../common/components/IconButton";
import { useHistory, Link } from "react-router-dom";
import { useUser } from "../../../../contexts/UserProvider";

const PUBLIC = process.env.PUBLIC_URL;

interface HeaderbarProps {}

export const Headerbar: FC<HeaderbarProps> = (props) => {
  const { user, logout } = useUser();
  let history = useHistory();

  const handlelogout = () => {
    logout().then(() => {
      history.push("/login");
    });
  };

  return (
    <div className={styles.headerbar}>
      <div className={styles.inner}>
        <div className={styles.item}>
          <Link to={"/"}>
            <div
              className={styles.logo}
              style={{ backgroundImage: `url(${PUBLIC}/images/logo.png)` }}
            ></div>
          </Link>
        </div>
        <div className={cn(styles.item, styles.actions)}>
          {user && (
            <div className={styles.actionEntries}>
              <IconButton>
                <IconUser className={styles.iconUser} />{" "}
                <div className={styles.userName}>{user.profile.email}</div>
              </IconButton>
              <IconButton
                className={styles.logoutButton}
                onClick={handlelogout}
              >
                <IconLogout />
              </IconButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
