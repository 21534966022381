export class Cookie {
  static getCookie = (
    cookies: string,
    cookieName: string
  ): string | undefined => {
    const cookiesList: Array<string> = cookies.split("; ");

    const accessTokenCookie = cookiesList.find((cookie) => {
      const [key] = cookie.split("=");
      return key === cookieName;
    });

    return accessTokenCookie
      ? accessTokenCookie.replace(`${cookieName}=`, "")
      : undefined;
  };

  static setCookie = (cookieName: string, value: string): void => {
    document.cookie = `${cookieName}=${value}; path=/;`;
  };

  static removeCookie = (cookieName: string): void => {
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  };
}
